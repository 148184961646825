.App {
  text-align: center;
  font-family: 'Fredoka One', cursive;
  background-color: black;
  color:white;
 
}

header{
 display: flex;
 flex-direction: row;
 height: 150px;
 color: black;
 font-size: larger;
 background-color: #4ED8FF;
}

.header-sides{
  width:33vw ;
  height: 223px;
  padding:8px;
  display: flex;
  justify-content: center;
}

.header-sides:hover{
cursor: pointer;
}

.header-center{
  width: 32vw;
  background-color:#4ED8FF ;
  height: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.title{
  color:black;
  font-family: 'Bakbak One', cursive;
}

.logo{
  height: 17vh;
  width:24vw;
  margin-top:4px;
  border: 3px black solid;
}

.HomeReturnBar{
  height: 5vh;
  width: 100vw;
  display: flex;
  align-items: center;
  background-color: #4ED8FF;
  padding: 5px;
  margin-top: 5px;
  color: rgb(43, 43, 43);
}

.HomeReturnBar:hover{
  background-color: rgb(172, 172, 234);
  cursor: pointer;
}

.home-logo{
  height: 30px;
  width:30px;
  margin-right: 30px;
}

.home{
  height: 100vh;
  border: 10px solid white;
  width:98vw;
}

nav{
  height: 5vh;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.nav-button{
  border: 2px solid white;
  font-size: 16px;
  width: 25vw;
  cursor:pointer;
}

.nav-button:hover{
  background-color: rgb(42, 41, 41);
}
.clients{
border: 10px solid white;
margin: 0;
padding: 0;

}


main{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
  padding: 30px;
}

.plumber{
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.contact-card{
  display: flex;
  flex-direction: column;
  height:60vh;
  align-items: center;
  justify-content: space-between;
}

.logo-background{
  margin-top: 5px;
  width:32vw ;
  height: 170px;
  background-color: #4ED8FF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-card{
  width:30.2vw ;
  height: 150px;
  background-color: #4ED8FF;
  border: 3px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-btn{
  background-color: #2e36a0; /* Green */
border: 2px solid rgb(0, 0, 0);
color: white;
font-weight: 400;
padding: 15px 32px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 18px;
border-radius: 25px;
}
.contact-btn:hover{
  background-color: #2d2add;
  color: rgb(158, 179, 198);
}
#call-btn{
  background-color: #00AB43;
  width:200px;
}
#chat-btn{
  background-color: #FDB400;
  width:200px;
}

.small-logo{
  height: 55px;
  width: 75px;
}

.card-link-card{
  background-color: lightblue;
  margin-top: 20px;
  border-radius: 25px;
  padding: 15px;
  height: 120px;
}

.card-link-card:hover{
  background-color: rgb(172, 172, 234);
}


h1{
  margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
  font-size: 500%;
  font-weight: bolder;
  color:rgb(78, 214, 254);
  font-family: 'Satisfy', cursive;
}

section{
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-box{
  border: 3px solid white;
  width: 60vw;
}

.card-url-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 650px;
  
}


.promo{
  width:100vw;
  height:10vh;
  text-align: center;
  display: flex;
  flex-direction: row ;
  border:#2439c4 solid 2px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: medium;
}

.promo:hover{
  cursor:pointer;
}

.fire{
  margin-top: 4px;
  height: 8vh;
  width: 8vw;
}

footer{
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  border-bottom-width: 0;
  padding: 10px;
}

.powered-by{
  /* margin-top: 80px; */
}

.footer-logo{
  border: 3px solid white;
  height: 80px;
  width: 100px;

}

@media (max-width:900px) {

  .home{
height: 900px;
width:95vw;

  }
  .header-sides {
   display:none;
  }
  .header-center{
    width: 170vw;
    background-color:#4ED8FF ;
    z-index: 2;
    /* display: block; */
  }
  .logo{
    height: 150px;
    width:60vw;
    margin-top:11px;
    border: 3px black solid;
    z-index:2;
  }
  .logo-background{
    width: 80vw;
  }
  .logo-card{
    width:68vw ;
    height: 140px;
    background-color: #4ED8FF;
    border: 3px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .HomeReturnBar{
    height: 5vh;
    width: 100vw;
    display: flex;
    align-items: center;
    background-color: #4ED8FF;
    padding: 5px;
   margin-top: 15px;
    color: rgb(43, 43, 43);
  }

  .contact-card{
    display: flex;
    flex-direction: column;
    height:130vh;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
  }
  
}